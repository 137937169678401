body {
  margin:0;
  top:0;
  background-color: hsl(1,1%,95%);
}

a {
  text-decoration: none;
}

.website-base-wrapper, .dokter-detail-wrapper {
  padding:3%;
}

.website-toolbar {
  padding:2% 3%;
}

.doctor-list-wrapper {
  padding-top: 2%;
}

.parentFlexRight {
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width:400px) {
  .doctor-thumbnail {
    object-fit: contain;
  }
}